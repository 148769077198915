import { PlatformControllerFlowAPI, type ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { toError, is403, isNetworkError } from './errors';

type Predicate = (e: unknown, flowAPI: ControllerFlowAPI | PlatformControllerFlowAPI) => boolean;
type Params = Parameters<ControllerFlowAPI['errorMonitor']['captureException']>;
type ExtendedParams = Params[1] & { additionalIgnorePredicates?: Predicate[] };

const isEditor403: Predicate = (e, flowAPI) => flowAPI.environment.isEditor && is403(e);
const DEFAULT_IGNORED_ERROR_PREDICATES: Predicate[] = [isNetworkError, isEditor403];

export function captureViewerException(
  flowAPI: ControllerFlowAPI | PlatformControllerFlowAPI,
  error: unknown,
  options?: ExtendedParams,
) {
  const additionalIgnorePredicates = options?.additionalIgnorePredicates ?? [];
  const isErrorIgnored = [...additionalIgnorePredicates, ...DEFAULT_IGNORED_ERROR_PREDICATES].some((isIgnored) =>
    isIgnored(error, flowAPI),
  );
  if (!isErrorIgnored) {
    flowAPI.errorMonitor.captureException(toError(error), options);
  }
}
